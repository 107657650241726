import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { navigateTo, createMultiPartFormBody } from '../../util/Util';
import { ApiKey, ApiUrl, WebUrl, AccessRight, TransactionType, TransactionStatus, PanelType, LanguageKey, DEFAULT_PAGE_SIZE, ReadWritePermission, Role, _TRANSACTION_STATUS } from '../../util/Constant';
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { stringIsNullOrEmpty, createFormBody, numberWithCurrencyFormat } from "../../util/Util";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Deposit from './Deposit.js';
import Withdrawal from './Withdrawal.js';
import Adjustment from './Adjustment.js';
import ApiEngine from '../../util/ApiEngine';
import ImageViewer from 'react-simple-image-viewer';
import { AsyncPaginate } from "react-select-async-paginate";
import { checkIfPermissionExist } from '../../util/PermissionChecker';
import { map } from "lodash";
import PinInput from 'react-pin-input';

/// <summary>
/// Author : -
/// </summary>

const WithdrawalEnquiry = props => {
  const _DEPOSIT = 1;
  const _WITHDRAWAL = 2;
  const _ADJUSTMENT = 3;
  const _NORMAL_TRANSACTION_TAB = 1;
  const _GATEWAY_TRANSACTION_TAB = 2;
  const _AUTO_WITHDRAWAL_TAB = 3;
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors } = useForm();
  const [calendarStartDate, setCalendarStartDate] = useState(moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [calendarEndDate, setCalendarEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [withdrawalApprovalModal, setWithdrawalApprovalModal] = useState(false);
  const [auditBankOption, setAuditBankOption] = useState([]);
  const [auditBankId, setAuditBankId] = useState('');
  const [auditBankAccountId, setAuditBankAccountId] = useState('');
  const [transaction, setTransaction] = useState();
  const [bankAccountOption, setBankAccountOption] = useState([]);
  const [showTransButton, setShowTransButton] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [transactionType, setTransactionType] = useState();
  const [transactionComponent, setTransactionComponent] = useState();
  const [memberId, setMemberId] = useState();
  const [initFlag, setInitFlag] = useState(false);
  const [transactionApiUrl, setTransactionApiUrl] = useState("");
  const [receiptImages, setReceiptImages] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showBonusOnly, setShowBonusOnly] = useState(false);
  const [roles, setRoles] = useState([]);
  const _userData = useSelector((state) => state.authState.userData);
  const isCompany = _userData.userRoleId === Role._SUPER_COMPANY || _userData.userRoleId === Role._COMPANY;
  let role1IsAllowed = checkIfPermissionExist(
    AccessRight._PAYMENT_GATEWAY_APPROVAL_PERMISSION,
    true
  );
  let role2IsAllowed = checkIfPermissionExist(
    AccessRight._TRANSACTION_QUEUE_PERMISSION,
    true
  );
  let role3IsAllowed = checkIfPermissionExist(
    AccessRight._TRANSACTION_APPROVAL_PERMISSION,
    true
  );
  let role4IsAllowed = checkIfPermissionExist(
    AccessRight._TRANSACTION_REJECTION_PERMISSION,
    true
  );
  // supervisor for revert 
  let role5IsAllowed = checkIfPermissionExist(
    AccessRight._TRANSACTION_IN_REVIEW_PERMISSION,
    true
  );

  const _STATUS_OPTIONS = [
    { value: TransactionStatus._OPEN, label: 'Open' },
    { value: TransactionStatus._APPROVED, label: 'Approved' },
    { value: TransactionStatus._REJECTED, label: 'Rejected' },
    { value: TransactionStatus._IN_REVIEW, label: 'In Review' },
    { value: TransactionStatus._IN_PROGRESS, label: 'In Progress' },
    { value: TransactionStatus._AUTO_APPROVED, label: 'Auto Approved' },
  ];

  const _TRANSACTION_OPTIONS = [
    //{ value: TransactionType._DEPOSIT, label: 'Deposit' },
    { value: TransactionType._WITHDRAWAL, label: 'Withdrawal' },
    //{ value: TransactionType._UPLINE_TRANSFER, label: 'Transfer' },
    //{ value: TransactionType._REFERRAL_BONUS, label: 'Shareholder Claim' },
    //{ value: TransactionType._SHAREHOLDER, label: 'Shareholder' },
    //{ value: TransactionType._COMMISSION, label: 'Commission' },
    //{ value: TransactionType._REWARD, label: "Reward Claim" },
    //{ value: TransactionType._FREE_SPIN, label: "Free Spin" },
    //{ value: TransactionType._VOUCHER, label: "Loyalty Voucher" },
    //{ value: TransactionType._BIRTHDAY_BONUS, label: "Birthday Bonus" },
    //{ value: TransactionType._DAILY_CHECK_IN, label: "Daily Check In" },
  ];

  const _PANEL_OPTIONS = [
    { value: PanelType._MEMBERSITE, label: 'MemberSite' },
    { value: PanelType._BACKOFFICE, label: 'BackOffice' },
  ];

  const [bankingChannelOption, setBankingChannelOption] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(_STATUS_OPTIONS);
  const [selectedTransaction, setSelectedTransaction] = useState(_TRANSACTION_OPTIONS);
  const [selectedPanel, setSelectedPanel] = useState(_PANEL_OPTIONS);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [username, setUsername] = useState("");
  const [label, setLabel] = useState("");
  const [bank, Setbank] = useState("");
  const _SELECTED_MEMBER_KEY = "selectedMemberKey";
  const [activeTab, setActiveTab] = useState(_NORMAL_TRANSACTION_TAB);
  const [loadingData, setloadingData] = useState(true);
  const [autoWithdrawalPaymentGatewayList, setAutoWithdrawalPaymentGatewayList] = useState([]);

  const [showPinEntry, setShowPinEntry] = useState(false);
  const [updateStatusTransactionData, setUpdateStatusTransactionData] = useState(null);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  useEffect(() => {
    sessionStorage.setItem(_SELECTED_MEMBER_KEY, username);
  }, [username]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
    register({ name: 'auditBankId' }, { required: "PLEASE_SELECT_BANK" });
    register({ name: 'auditBankAccountId' }, { required: "PLEASE_SELECT_BANK_ACCOUNT" });
  }, [props.username]);

  useEffect(() => {
    if (!props.fromUrl) {
      setSelectedStatus(_STATUS_OPTIONS.filter(x => x.value === 0 || x.value === 1 || x.value === 2));
      setSelectedTransaction(_TRANSACTION_OPTIONS.filter(x => x.value === 1 || x.value === 2));
    } else {
      setCalendarStartDate(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [props.fromUrl]);


  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (initFlag) {
      getAvailableAutoWithdrawalInstantPaySetting();
      getUserTransaction(activeTab);
    }
  }, [initFlag]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    switch (transactionType) {
      case _DEPOSIT:
        setTransactionComponent(<Deposit id={memberId} toggleModal={setTransactionModal} onComplete={getUserTransaction} />);
        break;
      case _WITHDRAWAL:
        setTransactionComponent(<Withdrawal id={memberId} toggleModal={setTransactionModal} onComplete={getUserTransaction} />);
        break;
      case _ADJUSTMENT:
        setTransactionComponent(<Adjustment id={memberId} toggleModal={setTransactionModal} onComplete={getUserTransaction} />);
        break;
      default:
        setTransactionComponent();
        break;
    }
  }, [transactionType]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(auditBankId)) {
      getBankAccount();
    }
  }, [auditBankId])

  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function getBankAccount() {
    var apiUrl = ApiUrl._API_GET_BANK_ACCOUNT_BY_BANK_ID;
    apiUrl += "?bankId=" + auditBankId + "&withdrawal=" + true;
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      const bankAccountList = [];
      responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
        if (bankAccount.withdrawalStatus) {
          bankAccountList.push({ label: bankAccount.accountNumber, value: bankAccount.id });
        }
      });

      setBankAccountOption(bankAccountList);
    }
    else {
      _dispatch(showMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }
  }

  async function getAvailableAutoWithdrawalInstantPaySetting() {
    var apiUrl = ApiUrl._API_GET_AVAILABLE_AUTO_WITHDRAWAL_INSTANT_PAY_SETTING;
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setAutoWithdrawalPaymentGatewayList(responseJson[ApiKey._API_DATA_KEY]);
    }
    else {
      _dispatch(showMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }
  }

  function prepareReceiptImage(imgString) {
    if (imgString != null) {
      let imgArray = [];
      //imgString = "../.." + imgString;
      imgArray.push(imgString);
      setReceiptImages(imgArray);
      openImageViewer(0);
      setTransactionApiUrl(transactionApiUrl);
    }
    else {
      _dispatch(showMessage(false, t('NO_RECEIPT_FOUND')));
    }
  }


  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    if (props.username && props.id) {
      setUsername(props.username);
      setMemberId(props.id);
      setLabel(props.username);
      if (isCompany || _userData.userRoleId === Role._PAYMENT_OFFICIAL_CS || _userData.userRole.includes("Supervisor")) { setShowTransButton(true); }
    }
    try {
      let channelResponseJson = await ApiEngine.get(ApiUrl._API_GET_BANKING_CHANNEL_TRANSACTION_ENQUIRY);
      if (channelResponseJson[ApiKey._API_SUCCESS_KEY]) {
        const bankingChannelList = [];
        bankingChannelList.push({ label: "Offline Banking", value: "Offline Banking" });
        channelResponseJson[ApiKey._API_DATA_KEY].forEach(function (bankingChannel) {
          bankingChannelList.push({ label: bankingChannel.instantPayGatewayString, value: bankingChannel.instantPayGatewayString });
        });

        setBankingChannelOption(bankingChannelList);
      }
      else {
        throw channelResponseJson[ApiKey._API_MESSAGE_KEY];
      }

      let userBankResponseJson = await ApiEngine.get(ApiUrl._API_GET_USER_BANK);
      if (userBankResponseJson[ApiKey._API_SUCCESS_KEY]) {
        const bankList = [];
        userBankResponseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
          bankList.push({ label: bank.bankName, value: bank.id });
        });
        setAuditBankOption(bankList);
      }
      else {
        throw userBankResponseJson[ApiKey._API_MESSAGE_KEY];
      }

      setInitFlag(true);
    }
    catch (ex) {
      _dispatch(showMessage(false, t(ex)));
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const getUserTransaction = async (tab) => {
    let status = [];
    let transactionType = [];
    let channel = [];
    let panel = [];
    if (!stringIsNullOrEmpty(selectedStatus)) {
      selectedStatus.map((selected) => {
        status.push(selected.value);
      })
    }
    if (!stringIsNullOrEmpty(selectedTransaction)) {
      selectedTransaction.map((selected) => {
        transactionType.push(selected.value);
      })
    }
    if (!stringIsNullOrEmpty(selectedChannel)) {
      selectedChannel.map((selected) => {
        channel.push(selected.value);
      })
    }
    if (!stringIsNullOrEmpty(selectedPanel)) {
      selectedPanel.map((selected) => {
        panel.push(selected.value);
      })
    }

    let fetchUrl = ApiUrl._API_GET_ALL_USER_TRANSACTION;
    fetchUrl += "?startDate=" + calendarStartDate + "&endDate=" + calendarEndDate + "&approvalStatus=" + status + "&isGatewayTrans=" + (tab === _GATEWAY_TRANSACTION_TAB ? true : false) + "&isAutoTxn=" + (tab === _AUTO_WITHDRAWAL_TAB ? true : false);
    fetchUrl += "&transactionType=" + transactionType + "&bankingChannel=" + channel + "&panel=" + panel + "&v=" + Date.now() + "&showBonusOnly=" + showBonusOnly;
    if (username !== "") {
      fetchUrl += "&username=" + label;
    }
    setTransactionApiUrl(fetchUrl);
  }

  // If is debit transaction and searched username is not row's username (means transffered to this searched username)
  // If is debit transaction and searched username is empty (viewing from overview)
  // If is transfer transaction and searched username is row's username (means transferred to this searched username)
  const isDebitTransaction = (transaction) => {
    return (_DEBIT_TRANSACTIONS.indexOf(transaction.transactionTypeId) >= 0 && transaction.memberUsername == username) ||
      (_DEBIT_TRANSACTIONS.indexOf(transaction.transactionTypeId) >= 0 && stringIsNullOrEmpty(username)) ||
      (transaction.transactionTypeId == TransactionType._UPLINE_TRANSFER && transaction.memberUsername == username) ||
      (transaction.transactionTypeId == TransactionType._WITHDRAWAL);
  }

  // If is not debit transaction 
  // If it is transfer type and username is not transaction username and searched username is not empty (viewing from account)
  const isCreditTransaction = (transaction) => {
    return _DEBIT_TRANSACTIONS.indexOf(transaction.transactionTypeId) < 0 ||
      (transaction.transactionTypeId == TransactionType._UPLINE_TRANSFER && transaction.memberUsername != username && !stringIsNullOrEmpty(username))
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const updateStatus = async (transaction, status, auditBankAccountId, autoWithdrawalInstantPaySettingId) => {
    let apiUrl = ApiUrl._API_UPDATE_TRANSACTION_STATUS
    apiUrl += "?transactionId=" + transaction.id + "&status=" + status;
    if (!stringIsNullOrEmpty(auditBankAccountId)) {
      apiUrl += "&auditBankAccountId=" + auditBankAccountId;
    }
    console.log(autoWithdrawalInstantPaySettingId);
    if (!stringIsNullOrEmpty(autoWithdrawalInstantPaySettingId)) {
      apiUrl += "&autoWithdrawalInstantPaySettingId=" + autoWithdrawalInstantPaySettingId;
    }
    console.log(apiUrl)

    let responseJson = await ApiEngine.post(apiUrl);
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    getUserTransaction(activeTab);

    // if success then send notification
    //if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    //    // Auto Transaction push notification is run from callback
    //    if (activeTab !== 3) {
    //        pushNotificationTransaction(transaction, status);
    //    }
    //}

    let params = [];
    params = {
      "transactionId": transaction.id,
      "status": status
    };
    if (!stringIsNullOrEmpty(auditBankAccountId)) {
      params["auditBankAccountId"] = auditBankAccountId;
    }
    if (!stringIsNullOrEmpty(autoWithdrawalInstantPaySettingId)) {
      params["autoWithdrawalInstantPaySettingId"] = autoWithdrawalInstantPaySettingId;
    }
    _dispatch(setSaveAdminLog("EDIT_TRANSACTION_STATUS", _history.location.pathname, params));
  }

  const pushNotificationTransaction = async (transaction, status) => {
    const { id, memberId, transactionTypeId } = transaction;
    let statusOption = "";
    let notificationType = "";

    selectedStatus.forEach(function (options) {
      if (options.value === status) {
        statusOption = options.label;
      }
    });

    if (transactionTypeId === 1) {
      notificationType = 2
    }
    else if (transactionTypeId === 2) {
      notificationType = 3
    }

    var apiUrl =
      ApiUrl._API_GET_PUSH_NOTIFICATION_BY_NOTIFICATIONTYPEID;
    apiUrl += "?memberId=" + memberId + "&notificationTypeId=" + notificationType + "&transactionId=" + id + "&target=USERNAME"; // hardcode first
    let responseJson = await ApiEngine.get(apiUrl);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(setIdle());
      _dispatch(
        showMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          t(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_MESSAGE_KEY])
        )
      );
    }
    else {
      throw t(responseJson[ApiKey._API_MESSAGE_KEY]);
    }
  }

  const _DEBIT_TRANSACTIONS = [TransactionType._WITHDRAWAL, TransactionType._UPLINE_TRANSFER];

  let _tableColumns = [
    {
      id: "id",
      Header: "ID",
      accessor: "id",
      minWidth: 100,
      Footer: () => {
        return <span><b>{t("TOTAL")}: </b></span>
      },
    },
    {
      Header: t("SUBMIT_TIME"),
      accessor: "submitTime",
      style: {
        whiteSpace: 'unset',
        wordBreak: 'break-word'
      },
      Cell: ({ row }) => {
        return <>{moment(row.original.submitTime).format("YYYY-MM-DD HH:mm:ss")}</>
      },
      minWidth: 100
    },
    {
      Header: t("UPDATED_TIME"),
      accessor: "auditedTime",
      style: {
        whiteSpace: 'unset',
        wordBreak: 'break-word'
      },
      Cell: ({ row }) => {
        if (row.original.transactionTypeId == 1 || row.original.transactionTypeId == 2) {
          if (row.original.approvalStatus == 0 || row.original.approvalStatus == 1) {
            return <>{moment(row.original.submitTime).format("YYYY-MM-DD HH:mm:ss")}</>
          }
          return <>{moment(row.original.auditedTime).format("YYYY-MM-DD HH:mm:ss")}</>
        }
        else {
          return <>{moment(row.original.submitTime).format("YYYY-MM-DD HH:mm:ss")}</>
        }
      },
      minWidth: 100
    },
    {
      Header: t("USERNAME"),
      accessor: "memberUsername",
      minWidth: 100,
    },
    {
      Header: t("FULLNAME"),
      accessor: "memberFullName",
      minWidth: 100
    },
    {
      Header: t("TYPE"),
      accessor: "transactionType",
      minWidth: 100,
      Cell: ({ row }) => {
        if (row.original.transactionTypeId == 6 && row.original.transferTo != username) {
          return <>{t(row.original.transactionType + " To " + row.original.transferTo)}</>

        }
        else if (row.original.transactionTypeId == 6 && row.original.transferTo == username) {
          return <>{t(row.original.transactionType + " To " + row.original.transferTo)}</>

        }
        else if (row.original.transactionTypeId == 1) {
          return <>{t(row.original.transactionType)}<br /> <div style={{ fontSize: "10px" }}>{t(row.original.bank)}</div></>
        }
        else if (row.original.transactionTypeId == 2) {
          return (
            <>
              {t(row.original.transactionType)}<br />
              <div style={{ fontSize: "10px" }}>{t(row.original.bank)}</div>
              <div style={{ fontSize: "10px" }}>{t(row.original.bankName)}</div>
            </>)
        }
        else {
          return <>{t(row.original.transactionType)}</>
        }
      }
    },
    {
      Header: t("STATUS"),
      accessor: "approvalStatusString",
      Cell: ({ row }) => {
        switch (row.original.approvalStatus) {
          case TransactionStatus._APPROVED:
            return <span className="badge badge-secondary badge-green">{t("APPROVED")}</span>
            break;
          case TransactionStatus._REJECTED:
            return <span className="badge badge-secondary badge-danger">{t("REJECTED")}</span>
            break;
          case TransactionStatus._IN_PROGRESS:
            return <span className="badge badge-secondary badge-warning" style={{ color: "black" }}>{t("PROCESSING")}</span>
            break;
          case TransactionStatus._IN_REVIEW:
            return <span className="badge badge-secondary badge-info" style={{ color: "black" }}>{t("IN_REVIEW")}</span>
            break;
          case TransactionStatus._AUTO_APPROVED:
            return <span className="badge badge-secondary badge-green">{t("AUTO_APPROVED")}</span>
            break;
          default:
            return <span className="badge badge-secondary badge-primary">{t(row.original.approvalStatusString)}</span>
            break;
        }
      },
      minWidth: 100
    },
    {
      id: "credit",
      Header: t("CREDIT"),
      accessor: "amount",
      minWidth: 100,
      Cell: ({ row }) => {
        if (isCreditTransaction(row.original)) {
          return <>{numberWithCurrencyFormat(parseFloat(row.original.amount), 3)}</>
        }
        else {
          return <></>
        }
      },
      Footer: ({ page }) => {
        const creditSum = page.reduce((sum, currentValue) => {
          if (currentValue.original.amount && isCreditTransaction(currentValue.original)) {
            return sum + parseFloat(currentValue.original.amount);
          }
          else {
            return sum;
          }
        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(creditSum), 3)}</b></span>
      }
    },
    {
      id: "bonusAmount",
      Header: t("BONUS"),
      accessor: "bonusAmount",
      minWidth: 100,
      Cell: ({ row }) => {
        if (isCreditTransaction(row.original)) {
          return <>{numberWithCurrencyFormat(parseFloat(row.original.bonusAmount), 3)}</>
        }
        else {
          return <></>
        }
      },
      Footer: ({ page }) => {
        const creditSum = page.reduce((sum, currentValue) => {
          if (currentValue.original.bonusAmount && isCreditTransaction(currentValue.original)) {
            return sum + parseFloat(currentValue.original.bonusAmount);
          }
          else {
            return sum;
          }
        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(creditSum), 3)}</b></span>
      }
    },
    {
      id: "serviceChargePercentage",
      Header: t("SERVICE_CHARGE_PERCENTAGE"),
      accessor: "serviceCharge",
      minWidth: 100,
      Cell: ({ row }) => {
        return <>{numberWithCurrencyFormat(parseFloat(row.original.serviceChargePercentage))}</>
      },
      Footer: ''
    },
    {
      id: "serviceCharge",
      Header: t("SERVICE_CHARGE"),
      accessor: "serviceCharge",
      minWidth: 100,
      Cell: ({ row }) => {
        return <>{numberWithCurrencyFormat(parseFloat(row.original.serviceCharge))}</>
      },
      Footer: ({ page }) => {
        const creditSum = page.reduce((sum, currentValue) => {
          if (currentValue.original.serviceCharge) {
            return sum + parseFloat(currentValue.original.serviceCharge);
          }
          else {
            return sum;
          }
        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(creditSum))}</b></span>
      }
    },
    {
      id: "debit",
      Header: t("DEBIT"),
      accessor: "amount",
      minWidth: 100,
      Cell: ({ row }) => {
        if (isDebitTransaction(row.original)) {
          return <>-{numberWithCurrencyFormat(parseFloat(row.original.amount))}</>
        }
        else {
          return <></>
        }
      },
      Footer: ({ page }) => {
        const debitSum = page.reduce((sum, currentValue) => {
          if (currentValue.original.amount && isDebitTransaction(currentValue.original)) {
            return sum + (parseFloat(currentValue.original.amount) * -1);
          }
          else {
            return sum;
          }
        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b></span>
      }
    },
    {
      Header: t("IP"),
      accessor: "ipAddress",
      Cell: ({ row }) => {
        return <a href="#">{row.original.ipAddress}</a>
      },
      width: 150
    },
    {
      Header: t("AUDITOR"),
      accessor: "auditedFullName",
      minWidth: 100
    },
    {
      Header: t("CHANNEL"),
      accessor: "channel",
      minWidth: 100
    },
    {
      Header: t("PANEL"),
      accessor: "panel",
      Cell: ({ row }) => {
        return <>{t(row.original.panel.toUpperCase())}</>
      },
      minWidth: 100
    },
    {
      Header: t("REMARK"),
      accessor: "remark",
    },
    {
      Header: t("DETAIL"),
      accessor: "detail",
    },
    {
      Header: t("LOCKED_BY"),
      accessor: "lockedByFullName",
    },
    {
      Header: t("LOCKED_TIME"),
      accessor: "lockedTime",
      minWidth: 100,
      Cell: ({ row }) => {
        return <>{!stringIsNullOrEmpty(row.original.lockedTime) ? moment(row.original.lockedTime).format("YYYY-MM-DD HH:mm:ss") : ""}</>
      },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
            <DropdownMenu>
              {(row.original.approvalStatus == TransactionStatus._OPEN || row.original.approvalStatus == TransactionStatus._IN_REVIEW) && <>
                {row.original.isInstant !== true && row.original.approvalStatus == TransactionStatus._OPEN && (row.original.lockedBy === _userData.userId || isCompany || role3IsAllowed) && <DropdownItem onClick={() => { row.original.transactionTypeId == TransactionType._WITHDRAWAL ? handleWithdrawalApproval(row.original) : updateStatus(row.original, TransactionStatus._APPROVED, null, null); }}><span className="text-success">{t("APPROVE")}</span></DropdownItem>}
                {row.original.isInstant !== true && row.original.approvalStatus == TransactionStatus._IN_REVIEW && row.original.transactionTypeId == TransactionType._WITHDRAWAL && (row.original.lockedBy === _userData.userId || isCompany || role3IsAllowed) && autoWithdrawalPaymentGatewayList.length > 0 &&
                  map(autoWithdrawalPaymentGatewayList, (item, index) => {
                    return (
                      <DropdownItem key={"�nstant-pay-" + index} onClick={() => { updateStatus(row.original, TransactionStatus._IN_REVIEW, null, item.id) }}><span className="text-success">{t("APPROVE") + "( " + item.instantPayGatewayString + " )"}</span></DropdownItem>
                    )
                  })
                }
                {row.original.isInstant !== true ?
                  <>{(row.original.lockedBy === _userData.userId || isCompany || role4IsAllowed) && !(row.original.approvalStatus == TransactionStatus._IN_REVIEW && row.original.transactionTypeId == TransactionType._WITHDRAWAL) ? <DropdownItem onClick={() => { updateStatusWithPin(row.original, TransactionStatus._REJECTED, null, null) }}><span className="text-danger">{t("REJECT")}</span></DropdownItem> : <></>}</>
                  :
                  <>{role1IsAllowed ? <DropdownItem onClick={() => { updateStatusWithPin(row.original, TransactionStatus._REJECTED, null) }}><span className="text-danger">{t("REJECT")}</span></DropdownItem> : <></>}</>
                }
                {row.original.approvalStatus == TransactionStatus._IN_REVIEW && row.original.transactionTypeId == TransactionType._WITHDRAWAL ?
                  <>{(row.original.lockedBy === _userData.userId || isCompany || role4IsAllowed) ? <DropdownItem onClick={() => { updateStatus(row.original, TransactionStatus._OPEN, null, null) }}><span className="text-primary">{t("MOVE_TO_NORMAL_WITHDRAWAL")}</span></DropdownItem> : <></>}</>
                  : ""
                }
                </>}

                {(row.original.lockedBy === _userData.userId || isCompany || role5IsAllowed) && (row.original.approvalStatus == TransactionStatus._IN_PROGRESS && row.original.transactionTypeId == TransactionType._WITHDRAWAL)
                    ? <DropdownItem onClick={() => { updateStatusWithPin(row.original, TransactionStatus._IN_REVIEW, null, null) }}><span className="text-warning">{t("REVERT_TO_IN_REVIEW")}</span></DropdownItem>
                    : <></>
                }
              {(row.original.lockedBy === _userData.userId || isCompany || role4IsAllowed) && (row.original.approvalStatus == TransactionStatus._IN_PROGRESS && row.original.transactionTypeId == TransactionType._WITHDRAWAL)
                ? <DropdownItem onClick={() => { updateStatusWithPin(row.original, TransactionStatus._REJECTED, null, null) }}><span className="text-danger">{t("REJECT")}</span></DropdownItem>
                : <></>   //Reject Option for In_Process instant transaction
              }


              <DropdownItem onClick={() => { processLockTransaction(row.original, true) }}><span className="text-warning">{t("VIEW_TRANSACTION")}</span></DropdownItem>
              {row.original.transactionTypeId == TransactionType._DEPOSIT && <>
                <DropdownItem onClick={() => { processLockTransaction(row.original, false) }}><span className="text-warning">{t("VIEW_RECEIPT")}</span></DropdownItem>
              </>}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" }
    }
  ]

  /// <summary>
  /// Author : -
  /// </summary>
  async function processLockTransaction(transaction, viewTransaction = true) {
    try {
      let responseJson = await ApiEngine.post(ApiUrl._API_PROCESS_LOCK_TRANSACTION + "?transactionId=" + transaction["id"]);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (viewTransaction) {
          navigateTo(_history, WebUrl._URL_TRANSACTION_DETAIL, transaction);
          _dispatch(setSaveAdminLog("TO_VIEW_TRANSACTION_DETAIL", _history.location.pathname, transaction));
        }
        else {
          /// for view receipt
          prepareReceiptImage(transaction.receipt);
          _dispatch(setSaveAdminLog("TO_VIEW_TRANSACTION_RECEIPT", _history.location.pathname, transaction));
        }
      }
      else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    }
    catch (error) {
      _dispatch(showMessage(false, error));
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const handleWithdrawalApproval = (transaction) => {
    //setTransaction(transaction);
    if (transaction.approvalStatus === TransactionStatus._IN_REVIEW) {
      updateStatus(transaction, TransactionStatus._IN_REVIEW)
    }
    else {
      setTransaction(transaction);
      setWithdrawalApprovalModal(!withdrawalApprovalModal)
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setCalendarStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setCalendarEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = (data, e) => {
    updateStatus(transaction, TransactionStatus._APPROVED, auditBankAccountId);
    setWithdrawalApprovalModal(!withdrawalApprovalModal)
    setTransaction();
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    setAuditBankAccountId('');
    setAuditBankId('');
    register({ name: 'auditBankId' }, { required: "PLEASE_SELECT_BANK" });
    register({ name: 'auditBankAccountId' }, { required: "PLEASE_SELECT_BANK_ACCOUNT" });
  }, [withdrawalApprovalModal])

  async function adminLog(tab) {
    let status = [];
    let transactionType = [];
    let channel = [];
    let panel = [];
    if (!stringIsNullOrEmpty(selectedStatus)) {
      selectedStatus.map((selected) => {
        status.push(selected.value);
      })
    }
    if (!stringIsNullOrEmpty(selectedTransaction)) {
      selectedTransaction.map((selected) => {
        transactionType.push(selected.value);
      })
    }
    if (!stringIsNullOrEmpty(selectedChannel)) {
      selectedChannel.map((selected) => {
        channel.push(selected.value);
      })
    }
    if (!stringIsNullOrEmpty(selectedPanel)) {
      selectedPanel.map((selected) => {
        panel.push(selected.value);
      })
    }

    let params = [];

    params = {
      "startDate": calendarStartDate,
      "endDate": calendarEndDate,
      "approvalStatus": status,
      //"isGatewayTrans": (tab === _GATEWAY_TRANSACTION_TAB ? true : false),
      "isAutoTxn": (tab === _AUTO_WITHDRAWAL_TAB ? true : false),
      "transactionType": transactionType,
      "bankingChannel": channel,
      "panel": panel,
      "showBonusOnly": showBonusOnly
    };

    if (username !== "") {
      params["username"] = label;
    }

    _dispatch(setSaveAdminLog("VIEW_TRANSACTION_ENQUIRY", _history.location.pathname, params));
    }

    const updateStatusWithPin = async (transaction, status, auditBankAccountId, autoWithdrawalInstantPaySettingId) => {

        // Store the parameters as an object in state
        const transactionItem = {
            transaction,
            status,
            auditBankAccountId,
            autoWithdrawalInstantPaySettingId,
        };
        setUpdateStatusTransactionData(transactionItem);

        setShowPinEntry(true);
    }

    async function onPinSubmit(data, e) {
        // verify pin
        let params = { transactionPinNumber: data };
        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(ApiUrl._API_VERIFY_USER_TRANSACTION_PIN_NUMBER, formBody);

        if (responseJson[ApiKey._API_SUCCESS_KEY] && updateStatusTransactionData != null) {
            setShowPinEntry(false);
            var item = updateStatusTransactionData;
            updateStatus(item.transaction, item.status, item.auditBankAccountId, item.autoWithdrawalInstantPaySettingId);
        }
        else {
            setShowPinEntry(false);
            _dispatch(showMessage(false, t("INCORRECT") + " " + t("PIN_NUMBER")));
        }
    }

    async function checkPinValue(data) {
        // Check if PIN has 4 digits
        if (data.length === 4) {
            await onPinSubmit(data); // Submit PIN for validation
        }
    }

  return (
    <div>
      <h1 className="page-header">{t("TRANSACTION_ENQUIRY") + (stringIsNullOrEmpty(props.username) ? "" : " - " + props.username)}</h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <PanelBody>
              <div className="row">
                <Modal isOpen={withdrawalApprovalModal} toggle={() => { setWithdrawalApprovalModal(!withdrawalApprovalModal) }} centered={true}>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <ModalHeader toggle={() => { setWithdrawalApprovalModal(!withdrawalApprovalModal) }}>{t("PLEASE_SELECT_AUDIT_BANK_ACCOUNT")}</ModalHeader>
                    <ModalBody>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("AUDIT_BANK")}</b></label>
                            <div className="col-md-7">
                              <Select name="auditBankId" options={auditBankOption}
                                placeholder={(auditBankOption.filter(option => option.value == auditBankId)[0] !== undefined) ? (auditBankOption.filter(option => option.value == auditBankId)[0].label) : ""}
                                value={auditBankOption.filter(option => option.value == auditBankId)}
                                onChange={(e) => {
                                  unregister('auditBankId');
                                  setAuditBankId(e.value);
                                }} />
                              {errors.auditBankId && <div className="invalid-feedback">{t(errors.auditBankId.message)}</div>}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("AUDIT_BANK_ACCOUNT")}</b></label>
                            <div className="col-md-7">
                              <Select name="auditBankAccountId" options={bankAccountOption}
                                placeholder={(bankAccountOption.filter(option => option.value == auditBankAccountId)[0] !== undefined) ? (bankAccountOption.filter(option => option.value == auditBankAccountId)[0].label) : ""}
                                value={bankAccountOption.filter(option => option.value == auditBankAccountId)}
                                onChange={(e) => {
                                  unregister('auditBankAccountId');
                                  setAuditBankAccountId(e.value);
                                }} />
                              {errors.auditBankAccountId && <div className="invalid-feedback">{t(errors.auditBankAccountId.message)}</div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <button type="submit" className="btn btn-primary">{t("CONFIRM")}</button>{' '}
                      <button type="button" className="btn btn-danger" onClick={() => { setWithdrawalApprovalModal(!withdrawalApprovalModal) }}>{t("CANCEL")}</button>
                    </ModalFooter>
                  </form>
                </Modal>
                {/*Transaction Modal*/}
                <Modal isOpen={transactionModal} toggle={() => { setTransactionModal(!transactionModal) }} size="lg" centered={true}>
                  <ModalBody>
                    {transactionComponent}
                  </ModalBody>
                </Modal>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group" style={{ display: "flex" }}>
                    <div className="input-group" style={{ width: "100%", flexFlow: "nowrap" }}>
                      <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-calendar"></i></span></div>
                      <div className="input-group-append" style={{ width: "100%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          alwaysShowCalendars={true}
                          timePicker={true}
                          timePickerSeconds={true}
                          onApply={handleEvent}
                          ranges={{
                            'Today': [moment().startOf('day'), moment().endOf('day')],
                            'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                            'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'This Year': [moment().startOf('year'), moment().endOf('year')]
                          }}>
                          <input type="text" className="form-control" value={calendarStartDate + " - " + calendarEndDate} />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-eye"></i></span>
                    </div>
                    <div className="input-group-append" style={{ width: "100%" }}>
                      <Select
                        styles={{
                          container: () => ({
                            width: "100%"
                          })
                        }}
                        value={selectedStatus}
                        options={_STATUS_OPTIONS}
                        isMulti
                        onChange={(e) => {
                          setSelectedStatus(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-sliders-h"></i></span>
                    </div>
                    <div className="input-group-append" style={{ width: "100%" }}>
                      <Select
                        styles={{
                          container: () => ({
                            width: "100%"
                          })
                        }}
                        value={selectedTransaction}
                        options={_TRANSACTION_OPTIONS}
                        isMulti
                        onChange={(e) => {
                          setSelectedTransaction(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-exchange-alt"></i></span>
                    </div>
                    <div className="input-group-append" style={{ width: "100%" }}>
                      <Select
                        styles={{
                          container: () => ({
                            width: "100%"
                          })
                        }}
                        value={selectedChannel}
                        options={bankingChannelOption}
                        isMulti
                        onChange={(e) => {
                          setSelectedChannel(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fab fa-youtube"></i></span>
                    </div>
                    <div className="input-group-append" style={{ width: "100%" }}>
                      <Select
                        styles={{
                          container: () => ({
                            width: "100%"
                          })
                        }}
                        value={selectedPanel}
                        options={_PANEL_OPTIONS}
                        isMulti
                        onChange={(e) => {
                          setSelectedPanel(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
                {stringIsNullOrEmpty(props.username) && <div className="col-lg-3">
                  <div className="input-group member-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fas fa-user"></i></span>
                    </div>
                    <AsyncPaginate
                      placeholder={label}
                      debounceTimeout={250}
                      loadOptions={getMemberList}
                      additional={{
                        page: 1,
                      }}
                      value={username}
                      onChange={(e) => {
                        if (!stringIsNullOrEmpty(e.value)) {
                          sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                        } else {
                          sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                        }
                        setLabel(e.label);
                        setUsername(e.label);
                      }}
                    />
                  </div>
                </div>}
                <div className="col-lg-2">
                  <div className="input-group mb-3" style={{ flexFlow: "nowrap" }}>
                    <div className="input-group-prepend">
                      <label>
                        <b>Show Bonus Only</b>
                      </label>
                    </div>
                    <div className="input-group-append" style={{ width: "100%" }}>
                      <input
                        className="risk-lvl-checkbox"
                        type="checkbox"
                        name="bonusCheckbox"
                        id="bonusCheckbox"
                        checked={showBonusOnly}
                        onChange={(e) => {
                          setShowBonusOnly(e.target.checked);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <button type="button" onClick={() => { getUserTransaction(activeTab); adminLog(activeTab); }} className="btn btn-primary">{t("SEARCH")}</button>
                </div>
              </div>
              {showTransButton && <div className="row m-t-5">
                <div className="col-lg-12">
                  <button type="button" className="btn btn-warning" onClick={() => { setTransactionModal(true); setTransactionType(_DEPOSIT) }}>{t("DEPOSIT")}</button>
                  <button type="button" style={{ marginLeft: "5px" }} className="btn btn-purple" onClick={() => { setTransactionModal(true); setTransactionType(_WITHDRAWAL) }}>{t("WITHDRAWAL")}</button>
                </div>
              </div>}
              <hr />
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={"transaction-page-tab " + classnames({ active: activeTab === _NORMAL_TRANSACTION_TAB })}
                    onClick={() => { toggleTab(_NORMAL_TRANSACTION_TAB); getUserTransaction(_NORMAL_TRANSACTION_TAB); adminLog(activeTab); }}>
                    <span>{t("NORMAL_TRANSACTION")}</span>
                  </NavLink>
                </NavItem>
                {/*<NavItem>*/}
                {/*  <NavLink*/}
                {/*    className={"transaction-page-tab " + classnames({ active: activeTab === _GATEWAY_TRANSACTION_TAB })}*/}
                {/*    onClick={() => { toggleTab(_GATEWAY_TRANSACTION_TAB); getUserTransaction(_GATEWAY_TRANSACTION_TAB); adminLog(activeTab); }}>*/}
                {/*    <span>{t("GATEWAY_TRANSACTION")}</span>*/}
                {/*  </NavLink>*/}
                {/*</NavItem>*/}
                <NavItem>
                  <NavLink
                    className={"transaction-page-tab " + classnames({ active: activeTab === _AUTO_WITHDRAWAL_TAB })}
                    onClick={() => { toggleTab(_AUTO_WITHDRAWAL_TAB); getUserTransaction(_AUTO_WITHDRAWAL_TAB); adminLog(activeTab); }}>
                    <span>{t("AUTO_TRANSACTION")}</span>
                  </NavLink>
                </NavItem>
                {role2IsAllowed && <NavItem className="clear-cache">
                  <NavLink
                    onClick={() => window.open(WebUrl._URL_TRANSACTION_QUEUE)}>
                    <span>{t("OPEN_PLAYER_QUEUE")}</span>
                  </NavLink>
                </NavItem>}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={_NORMAL_TRANSACTION_TAB}>
                  <>
                  </>
                </TabPane>
                {/*<TabPane tabId={_GATEWAY_TRANSACTION_TAB}>*/}
                {/*  <>*/}
                {/*  </>*/}
                {/*</TabPane>*/}
                <TabPane tabId={_AUTO_WITHDRAWAL_TAB}>
                  <>
                  </>
                </TabPane>
              </TabContent>
              <ReactTable fetchUrl={transactionApiUrl} columns={_tableColumns} renderFooter={true} />
              {isViewerOpen && (
                <ImageViewer
                  src={receiptImages}
                  currentIndex={currentImage}
                  onClose={closeImageViewer}
                />
              )}
            </PanelBody>
          </div>
        </div>

          </Panel>
          <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" isOpen={showPinEntry} centered>
              <ModalBody>
                  <form onSubmit={handleSubmit(onPinSubmit)}>
                      <div className="display-wrapper">
                          <button type="button" style={{
                              alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", float: "right", fontWeight: "bold"
                          }} onClick={() => { setShowPinEntry(false); }} data-dismiss="modal" aria-hidden="true">&times;</button>
                          <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_PIN")}</strong>
                          <PinInput
                              length={4}
                              initialValue=""
                              secret={true}
                              focus={true}
                              autoSelect={true}
                              type="numeric"
                              onChange={(value, index) => {
                                  checkPinValue(value, index);
                              }}
                              inputMode="numeric"
                              inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                              inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                              regexCriteria={/^[ 0-9_@./#&+-]*$/}
                          />
                          {/*<button type="submit" style={{ position: "relative", marginTop: "30px", width: "250px" }} className="btn btn-block btn-brand-gradient btn-sticky-bottom">{t("SUBMIT_TRANSACTION")}</button>*/}
                          {/*<div className="invalid-feedback" style={{ fontSize: "12px", marginTop: "1rem" }}>{invalidPin && t("PIN_DOES_NOT_MATCH")}{!invalidPin && <span>&nbsp;</span>}</div>*/}

                      </div>
                  </form>
              </ModalBody>
          </Modal>
    </div>
  )
}

export default WithdrawalEnquiry;