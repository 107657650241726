import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { navigateTo, numberWithCommas, stringIsNullOrEmpty } from "../../util/Util";
import {
    ApiUrl,
    ApiKey,
    WebUrl,
    AccessRight,
    LanguageKey,
    AllVipOption
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useDispatch } from "react-redux";
import { showMessage } from "../../redux/AppAction";

/// <summary>
/// Author : -
/// </summary>

const ManageBankGroupSetting = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _dispatch = useDispatch();
    const [bankGroupData, setBankGroupData] = useState([]);
    //let urlEncodedStr = encodeURIComponent(btoa(AccessRight._BANK_GROUP_SETTING_PERMISSION));

    let _tableColumns = [
        {
            Header: "MemberNodeIdEnding",
            accessor: "memberNodeIdEnding",
        },
        {
            Header: "Banks",
            accessor: "",
            Cell: ({ row }) =>
            (
                <table style={{ border: "none", padding: "0px", width:"100%" }} >
                    <td style={{ border: "none", padding: "0px", width: "100%" }}>
                        {row.original.bankList.map(x =>
                            <tr >
                                <td style={{ width: "20%" }}>{x.bankName}</td>
                                <td style={{ width: "40%" }}>{x.accountHolderName}</td>
                                <td style={{ width: "40%" }}>{x.accountNumber}</td>
                            </tr>
                        )}
                    </td>
                </table>
            ),
            disableSortBy: true,
            disableFilters: true,
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) =>
            (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default">
                            <i className="fas fa-cog"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => {
                                    navigateTo(
                                        _history,
                                        WebUrl._URL_CREATE_OR_UPDATE_BANK_GROUP,
                                        row.original
                                    );
                                }}
                            >
                                <span className="text-warning">{t("EDIT")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
        },
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        GetAllBankGroupSetting();
    }

    function GetAllBankGroupSetting() {
        fetch(ApiUrl._API_GET_BANK_GROUP_SETTING, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                /*"Permission": urlEncodedStr*/
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    console.log(responseJson);
                    setBankGroupData(responseJson[ApiKey._API_DATA_KEY]);
                }
            });
    }

    return (
        <div>
            <h1 className="page-header">
                {t("MANAGE_BANK_GROUP_SETTING")}
                {(
                    <NavigationButton
                        history={_history}
                        url={WebUrl._URL_CREATE_OR_UPDATE_BANK_GROUP}
                        buttonText={t("ADD_BANK_GROUP_SETTING")}
                    />
                )}
            </h1>
            <Panel>
                <PanelBody>
                    <ReactTable data={bankGroupData} columns={_tableColumns} />
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ManageBankGroupSetting;
