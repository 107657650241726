import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import Select from "react-select";
import {
    Panel,
    PanelHeader,
    PanelBody,
} from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const BankGroupSettingDetail = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();

    const [memberNodeIdEnding, setMemberNodeIdEnding] = useState("");
    const [bankSettingModal, setBankSettingModal] = useState(false);
    const [bankGroupData, setBankGroupData] = useState([]);
    const [bankAccountOption, setBankAccountOption] = useState([]);
    const [bankAccountId, setBankAccountId] = useState("");

    const memberNodeIdEndingOptions = Array.from({ length: 10 }, (_, index) => ({
        value: index.toString(),
        label: index.toString(),
    }));

    const {
        handleSubmit: bankFormHandleSubmit,
        errors: bankFormErrors,
    } = useForm();

    let _tableColumns = [
        {
            Header: "BANK",
            accessor: "bankName",
        },
        {
            Header: t("BANK_ACCOUNT_HOLDER_NAME"),
            accessor: "accountHolderName",
        },
        {
            Header: "BANK_ACCOUNT_NUMBER",
            accessor: "accountNumber",
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
            },
        },
        {
            Header: "VIP_LEVEL",
            accessor: "vipLevel",
        },
        {
            Header: "MINIMUM_DEPOSIT",
            accessor: "minimumDeposit",
            width: 100,
        },
        {
            Header: "MAXIMUM_DEPOSIT",
            accessor: "maximumDeposit",
            width: 100,
        },
        {
            id: "account",
            Header: "ACCOUNT_STATUS",
            accessor: (d) => {
                return d.accountStatus ? (
                    <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span>
                ) : (
                    <span className="badge badge-secondary badge-danger">
                        {t("SUSPENDED")}
                    </span>
                );
            },
        },
        {
            id: "deposit",
            Header: "DEPOSIT_STATUS",
            accessor: (d) => {
                return d.depositStatus ? (
                    <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span>
                ) : (
                    <span className="badge badge-secondary badge-danger">
                        {t("SUSPENDED")}
                    </span>
                );
            },
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default">
                            <i className="fas fa-cog"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => updateBankAccountStatus(row.original)}>
                                {row.original.accountStatus == 1 ? t("DISABLE") : t("ENABLE")}
                            </DropdownItem>
                            <DropdownItem onClick={() => deleteMembernodeIdEnding(row.original.id)}>
                                <span className="text-warning">{t("DELETE")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" },
        },
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    function updateBankAccountStatus(data) {
        let params = {
            id: data["id"],
            accountStatus: !data["accountStatus"]
        };

        let formBody = createFormBody(params);
        _dispatch(setBusy());

        fetch(
            ApiUrl._API_CREATE_OR_UPDATE_BANK_ACCOUNT_DETAIL, 
            {
                method: ApiKey._API_POST,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
                body: formBody,
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                _dispatch(setIdle());
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        t(responseJson[ApiKey._API_MESSAGE_KEY])
                    )
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    getAllBankAccountByMemberNodeIdEnding();
                }
            });
    }

    const fetchBankAccountMemberNodeIdEnding = async (id) => {
        try {
            const response = await fetch(ApiUrl._API_GET_BANK_ACCOUNT_DETAIL + "?bankAccountId=" + id, {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            });

            const responseJson = await response.json();
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var memberNodeIdEnding = responseJson[ApiKey._API_DATA_KEY]["memberNodeIdEnding"];
                var memberNodeIds = [];
                if (!stringIsNullOrEmpty(memberNodeIdEnding)) {
                    memberNodeIds = memberNodeIdEnding.split(",").map(Number);
                }
                return memberNodeIds;
            }
            else {
                console.log(responseJson);
                return null;
            }
        }
        catch (error) {
            console.error("Error fetching bank account memberNodeIdEnding:", error.message);
            return null;
        }
    };

    const deleteMembernodeIdEnding = async (id) => {

        let memberNodeIds = await fetchBankAccountMemberNodeIdEnding(id);
        memberNodeIds = memberNodeIds.filter((nodeId) => nodeId !== Number(memberNodeIdEnding));
        var updateMemberNodeIdEnding = memberNodeIds.sort((a, b) => a - b).join(",");

        let params = {
            id: id,
            memberNodeIdEnding: updateMemberNodeIdEnding
        };

        let formBody = createFormBody(params);
        _dispatch(setBusy());

        fetch(ApiUrl._API_CREATE_OR_UPDATE_BANK_ACCOUNT_DETAIL, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                _dispatch(setIdle());
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        t(responseJson[ApiKey._API_MESSAGE_KEY])
                    )
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    getAllBankAccountByMemberNodeIdEnding();
                }
            });
    }

    function toggleModal() {
        if (bankSettingModal) {
            setBankAccountId("");
        }
        else {
            getBankAccountOption();
        }

        setBankSettingModal(!bankSettingModal);
    }

    const submitBankSettingForm = async (data, e) => {

        let memberNodeIds = await fetchBankAccountMemberNodeIdEnding(bankAccountId);

        if (!memberNodeIds.includes(Number(memberNodeIdEnding))) {
            memberNodeIds.push(Number(memberNodeIdEnding));
        }

        var updateMemberNodeIdEnding = memberNodeIds.sort((a, b) => a - b).join(",");

        let params = {
            id: bankAccountId,
            memberNodeIdEnding: updateMemberNodeIdEnding
        };

        let formBody = createFormBody(params);
        _dispatch(setBusy());

        fetch(ApiUrl._API_CREATE_OR_UPDATE_BANK_ACCOUNT_DETAIL, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                _dispatch(setIdle());
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        t(responseJson[ApiKey._API_MESSAGE_KEY])
                    )
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    toggleModal();
                    getAllBankAccountByMemberNodeIdEnding();
                }
            });
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (memberNodeIdEnding !== undefined) {
            getAllBankAccountByMemberNodeIdEnding();
        }
        console.log(memberNodeIdEnding)
    }, [memberNodeIdEnding]);

    async function init() {
        if (_location.state) {
            setMemberNodeIdEnding(_location.state.memberNodeIdEnding); 
        }
    }

    function getAllBankAccountByMemberNodeIdEnding() {
        if (!stringIsNullOrEmpty(memberNodeIdEnding)) {
            fetch(
                ApiUrl._API_GET_BANK_ACCOUNT_BY_MEMBER_NODE_ID_ENDING +
                "?memberNodeIdEnding=" +
                memberNodeIdEnding,
                {
                    method: ApiKey._API_GET,
                    headers: {
                        "Content-Type": ApiKey._API_FORM_URLENCODED,
                        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                    },
                }
            )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setBankGroupData(responseJson[ApiKey._API_DATA_KEY])
                }
            });
        }
    }

    function getBankAccountOption() {
        fetch(ApiUrl._API_GET_BANK_ACCOUNT_WITH_NULL_MEMBER_NODE_ID_ENDING +
            "?memberNodeIdEnding=" +
            memberNodeIdEnding, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
        .then((response) => response.json())
        .then((responseJson) => {
            const bankList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
                bankList.push({ label: bankAccount.bankName + " - " + bankAccount.accountHolderName + " - " + bankAccount.accountNumber, value: bankAccount.id });
            });

            setBankAccountOption(bankList);
        });
    }

    return (
        <div>
            <h1 className="page-header">
                {!stringIsNullOrEmpty(memberNodeIdEnding)
                    ? t("EDIT_BANK_GROUP_SETTING")
                    : t("ADD_NEW_BANK_GROUP_SETTING")}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="form-group row m-b-15">
                                                <label className="col-md-4 col-form-label">
                                                    <b>{t("MEMBER_NODE_ID_ENDING")}</b>
                                                </label>
                                                <div className="col-md-7">
                                                    <Select
                                                        options={memberNodeIdEndingOptions}
                                                        value={memberNodeIdEndingOptions.filter(
                                                            (option) => option.value === memberNodeIdEnding
                                                        )}
                                                        placeholder={
                                                            memberNodeIdEndingOptions.filter(
                                                                (option) => option.value === memberNodeIdEnding
                                                            )[0] !== undefined
                                                                ? memberNodeIdEndingOptions.filter(
                                                                    (option) =>
                                                                        option.value === memberNodeIdEnding
                                                                )[0].label
                                                                : "Select member node id ending number"
                                                        }
                                                        onChange={(selected) => setMemberNodeIdEnding(selected.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
            {!stringIsNullOrEmpty(memberNodeIdEnding) && (
                <div className="row">
                    <div className="col-xl-12">
                        <Panel>
                            <PanelHeader>{t("BANK_ACCOUNT")}</PanelHeader>
                            <PanelBody>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            style={{ float: "right" }}
                                            onClick={() => toggleModal()}
                                        >
                                            <i className="fa fa-plus-circle m-r-10"></i>
                                            {t("ADD_BANK_ACCOUNT")}
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <ReactTable
                                            data={bankGroupData}
                                            columns={_tableColumns}
                                        />
                                    </div>
                                </div>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            )}
            <div className="row">
                <Modal isOpen={bankSettingModal} toggle={() => toggleModal()}>
                    <form onSubmit={bankFormHandleSubmit(submitBankSettingForm)}>
                        <ModalHeader toggle={() => toggleModal()}>
                            {t("ADD_BANK_ACCOUNT")}
                        </ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("BANK_ACCOUNT")}</b>
                                        </label>
                                        <div className="col-md-7">
                                            <Select
                                                name="bankAccountId"
                                                options={bankAccountOption}
                                                onChange={(selected) => setBankAccountId(selected.value)}
                                            />
                                            {bankFormErrors.bankAccountId && (
                                                <div className="invalid-feedback">
                                                    {t(bankFormErrors.bankAccountId.message)}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="btn btn-white"
                                onClick={() => toggleModal()}
                            >
                                {t("CLOSE")}
                            </button>
                            <button type="submit" className="btn btn-primary">
                                {t("SUBMIT")}
                            </button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
        </div>
    );
};

export default BankGroupSettingDetail;